import * as Sentry from "@sentry/browser";
import { MobileMenu } from "./components/MobileMenu";
import { ENVIRONMENT } from "./utils/constants";
import "./components/Nav";
import { Search } from "./components/Search";

if (window.mrc.ENVIRONMENT !== ENVIRONMENT.DEVELOPMENT) {
  const disallowedCrawlers = ["Bytespider", "Googlebot", "HeadlessChrome"];

  Sentry.init({
    environment: window.mrc.ENVIRONMENT,
    release: window.mrc.VERSION,
    dsn: "https://7f01a50d584b7a6c30832bfd247c062f@o4505641672310784.ingest.sentry.io/4505643187699712",
    integrations: [
      Sentry.browserTracingIntegration({
        tracePropagationTargets: ["localhost", "https://memphisrum.club"],
      }),
      Sentry.replayIntegration(),
    ],
    beforeSend: (event) => {
      const browserNameTag = event.tags["browser.name"] as string;

      // Discard event if it's from a disallowed crawler
      if (disallowedCrawlers.includes(browserNameTag)) {
        return null;
      }

      return event;
    },
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0,
  });
}

// Variables
const urlParams = new URLSearchParams(window.location.search);
const searchFormToggleButton = <HTMLButtonElement>(
  document.getElementById("search-trigger-btn")
);
const searchFormContainer = <HTMLDivElement>document.getElementById("search");
const mobileMenuBtn = <HTMLButtonElement>(
  document.getElementById("mobile-menu-toggle")
);
const mobileNav = document.getElementById("main-site-nav");
const mobileMenu = new MobileMenu({
  button: mobileMenuBtn,
  navigation: mobileNav,
});
const siteSearch = new Search({
  toggleButton: searchFormToggleButton,
  container: searchFormContainer,
});
const colorSchemePrefQuery = window.matchMedia("(prefers-color-scheme: dark)");
const colorSchemePrefChangeHandler = (e) => {
  if (e.matches) {
    document.body.classList.add("dark-mode");
  } else {
    document.body.classList.remove("dark-mode");
  }
};

if (colorSchemePrefQuery.matches) {
  document.body.classList.add("dark-mode");
}

/**
 * Init page
 */
if (urlParams.get("ref") === "search") {
  window.gtag("event", "search", {
    search_term: decodeURIComponent(urlParams.get("search_term")),
  });
}

/**
 * Event Listeners
 */
if (colorSchemePrefQuery?.addEventListener) {
  colorSchemePrefQuery.addEventListener("change", colorSchemePrefChangeHandler);
} else {
  colorSchemePrefQuery.addListener(colorSchemePrefChangeHandler);
}

siteSearch.toggleButton.addEventListener("click", () => {
  siteSearch.toggle();
});

/**
 * Adapted from CSS-Tricks article: https://css-tricks.com/books/greatest-css-tricks/scroll-animation/
 */
window.addEventListener(
  "scroll",
  () => {
    if (document.body.offsetWidth <= 600) {
      const scrollValue: number =
        window.scrollY / (document.body.offsetHeight - window.innerHeight);
      document.body.style.setProperty("--scroll", scrollValue.toString());
    }
  },
  false
);

/**
 * Pagefind search init
 */
window.addEventListener("DOMContentLoaded", () => {
  new window.PagefindUI({
    element: "#search",
    debounceTimeoutMs: 500,
    showSubResults: false,
    showEmptyFilters: false,
    ranking: {
      termSimilarity: 2.0,
      termSaturation: 1.0,
    },
    processTerm: (term: string) => {
      let newTerm = term;

      if (term === "C<>H" || term === "<>H") {
        newTerm = `"${term}"`;
      }

      return newTerm;
    },
    processResult: (result: {
      url: string;
      raw_url: string;
      meta: { image: string };
    }) => {
      const hasImageQualityParam = result.meta.image.includes("q_auto:eco");
      const pagefindUiInput = <HTMLInputElement>(
        siteSearch.container.querySelector(".pagefind-ui__search-input")
      );

      if (hasImageQualityParam) {
        result.meta.image = result.meta.image.replace(/q_auto:eco/, "q_33");
      } else {
        result.meta.image = result.meta.image.replace(/upload/, "upload/q_33");
      }

      result.url = `${result.url}?ref=search&search_term=${encodeURIComponent(
        pagefindUiInput.value
      )}`;

      return result;
    },
  });
});

mobileMenu.setupEventListeners();
